import polyamideMultiPointLockingEuroSeries from '../assets/thermal break profile/Polyamide 19.5 multi-point locking euro series.png';
import img4 from '../assets/thermal break profile/C12.png';
import img5 from '../assets/thermal break profile/C14.8-6.png';
import img6 from '../assets/thermal break profile/C14.png';
import img7 from '../assets/thermal break profile/C16-1.png';
import img8 from '../assets/thermal break profile/C20-1.png';
import img9 from '../assets/thermal break profile/C25.png';
import img10 from '../assets/thermal break profile/I12.png';
import img11 from '../assets/thermal break profile/I14.png';
import img12 from '../assets/thermal break profile/I16.png';
import img13 from '../assets/thermal break profile/LDG-19.4.png';



export const Thermalprofilelist = [
    
    {
        id:1,
        image: polyamideMultiPointLockingEuroSeries,
        name: "Polyamide 19.5 multi-point locking euro series",
        desc: "Polyamide 19.5 multi-point locking euro series"
    },
    {
        id:2,
        image: img4,
        name: "Polyamide thermal break profile PA66 GF30%  C 12mm",
        desc: "Polyamide thermal break profile PA66 GF30%  C 12mm"
    },
    {
        id:3,
        image: img5,
        name: "Polyamide thermal break profile PA66 GF30% C 14.8-6mm",
        desc: "Polyamide thermal break profile PA66 GF30% C 14.8-6mm"
    },
    {
        id:4,
        image: img6,
        name: "Polyamide thermal break profile PA66 GF30% C 14mm",
        desc: "Polyamide thermal break profile PA66 GF30% C 14mm"
    },
    {
        id:5,
        image: img7,
        name: "Polyamide thermal break profile PA66 GF30% C 16-1mm",
        desc: "Polyamide thermal break profile PA66 GF30% C 16-1mm"
    },
    {
        id:6,
        image: img8,
        name: "Polyamide thermal break profile PA66 GF30% C 20-1mm",
        desc: "Polyamide thermal break profile PA66 GF30% C 20-1mm"
    },
    {
        id:7,
        image: img9,
        name: "Polyamide thermal break profile PA66 GF30% C 25mm",
        desc: "Polyamide thermal break profile PA66 GF30% C 25mm"
    },
    {
        id:8,
        image: img10,
        name: "Polyamide thermal break profile PA66 GF30% I 12mm",
        desc: "Polyamide thermal break profile PA66 GF30% I 12mm"
    },
    {
        id:9,
        image: img11,
        name: "Polyamide thermal break profile PA66 GF30% I 14mm",
        desc: "Polyamide thermal break profile PA66 GF30% I 14mm"
    },
    {
        id:10,
        image: img12,
        name: "Polyamide thermal break profile PA66 GF30% I 16mm",
        desc: "Polyamide thermal break profile PA66 GF30% I 16mm"
    },
    {
        id:11,
        image: img13,
        name: "Polyamide thermal break profile PA66 GF30% LDG 19.4mm",
        desc: "Polyamide thermal break profile PA66 GF30% LDG 19.4mm"
    },
    
] ;