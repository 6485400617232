// import img1 from '../assets/thermal break aluminium profile/3 in 1 openable thermal break with safety grill window profile.png'
// import img2 from '../assets/thermal break aluminium profile/aluminium thermal break openable window.png'
// import img3 from '../assets/thermal break aluminium profile/aluminium thermal break profile cashnet window.png'
// import img4 from '../assets/thermal break aluminium profile/aluminium thermal break profile sliding window.png'
// import img5 from '../assets/thermal break aluminium profile/polyamide aluminium thermal break profile.png'
// import img6 from '../assets/thermal break aluminium profile/polyamide Thermal barrier extrusion profile.png'
import img1 from '../assets/thermal break aluminium profile/3 in 1 openable thermal break with safety grill window profile .jpg'
import img2 from '../assets/thermal break aluminium profile/aluminium thermal break openable window.jpg'
import img3 from '../assets/thermal break aluminium profile/aluminium thermal break profile cashnet window.jpg'
import img4 from '../assets/thermal break aluminium profile/aluminium thermal break profile sliding window.jpg'
import img5 from '../assets/thermal break aluminium profile/polyamide aluminium thermal break profile.jpg'
import img6 from '../assets/thermal break aluminium profile/polyamide Thermal barrier extrusion profile.png'



export const Thermalaluminiumlist = [
    {
        id:1,
        image: img1,
        name: "3 in 1 openable thermal break with safety grill window profile",
        desc: "3 in 1 openable thermal break with safety grill window profile"
    },
    {
        id:2,
        image: img2,
        name: "Aluminium thermal break openable window",
        desc: "Aluminium thermal break openable window"
    },
    {
        id:3,
        image: img3,
        name: "Aluminium thermal break profile cashnet window",
        desc: "Aluminium thermal break profile cashnet window"
    },
    {
        id:4,
        image: img4,
        name: "Aluminium thermal break profile sliding window",
        desc: "Aluminium thermal break profile sliding window"
    },
    {
        id:5,
        image: img5,
        name: "Polyamide aluminium thermal break profile",
        desc: "Polyamide aluminium thermal break profile"
    },
    {
        id:6,
        image: img6,
        name: "Polyamide Thermal barrier extrusion profile",
        desc: "Polyamide Thermal barrier extrusion profile"
    },
] ;