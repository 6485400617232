import image1 from '../assets/products1.png'
import image2 from '../assets/product-2.png'
import image3 from '../assets/product3.png'
import image4 from '../assets/thermalbreakprofile.jpg'
import wooden1 from '../assets/wooden coating/wooden-1.png';
import wooden2 from '../assets/wooden coating/wooden-2.png';
import wooden3 from '../assets/wooden coating/wooden-3.png';
import wooden4 from '../assets/wooden coating/wooden-4.png';
import wooden9 from '../assets/wooden coating/wooden-9.png';
import wooden14 from '../assets/wooden coating/wooden-14.png';
import wooden15 from '../assets/wooden coating/wooden-15.png';
import wooden22 from '../assets/wooden coating/wooden-22.png';
import wooden23 from '../assets/wooden coating/wooden-23.png';
import wooden37 from '../assets/wooden coating/wooden-37.png';
import wooden41 from '../assets/wooden coating/wooden-41.png';
import wooden52 from '../assets/wooden coating/wooden-52.png';
import wooden54 from '../assets/wooden coating/wooden-54.png';
import wooden67 from '../assets/wooden coating/wooden-67.png';
import wooden72 from '../assets/wooden coating/wooden-72.png';

export const Woodencoatinglist = [
    {
        id:1,
        image: wooden1,
        name: "Wooden product - 1",
        desc: "Code 1"
    },
    {
        id:2,
        image: wooden2,
        name: "Wooden product - 2",
        desc: "Code 2"
    },
    {
        id:3,
        image: wooden3,
        name: "Wooden product - 3",
        desc: "Code 3"
    },
    {
        id:4,
        image: wooden4,
        name: "Wooden product - 4",
        desc: "Code 4"
    },
    {
        id:5,
        image: wooden9,
        name: "Wooden product - 5",
        desc: "Code 9"
    },
    {
        id:6,
        image: wooden14,
        name: "Wooden product - 6",
        desc: "Code 14"
    },
    {
        id:7,
        image: wooden15,
        name: "Wooden product - 7",
        desc: "Code 15"
    },
    {
        id:8,
        image: wooden22,
        name: "Wooden product - 8",
        desc: "Code 22"
    },
    {
        id:9,
        image: wooden23,
        name: "Wooden product - 9",
        desc: "Code 23"
    },
    {
        id:10,
        image: wooden37,
        name: "Wooden product - 10",
        desc: "Code 37"
    },
    {
        id:11,
        image: wooden41,
        name: "Wooden product - 11",
        desc: "Code 41"
    },
    {
        id:12,
        image: wooden52,
        name: "Wooden product - 12",
        desc: "Code 52"
    },
    {
        id:13,
        image: wooden54,
        name: "Wooden product - 13",
        desc: "Code 54"
    },
    {
        id:14,
        image: wooden67,
        name: "Wooden product - 14",
        desc: "Code 67"
    },
    {
        id:15,
        image: wooden72,
        name: "Wooden product - 15",
        desc: "Code 72"
    }
] ;