import img1 from '../assets/powder coating/aluminium powder coating.png';
import img2 from '../assets/powder coating/powder coating plant.png';
import img3 from '../assets/powder coating/powder coating process 2.png';
import img4 from '../assets/powder coating/powder coating product.png';
import img5 from '../assets/powder coating/powder coating process.png';
import img6 from '../assets/powder coating/power-free-conveyorised-powder-coating-plants.png';

export const Powdercoatinglist = [
    {
        id:1,
        image: img1,
        name: "Thermal Break Profile",
        desc: " "
    },
    {
        id:2,
        image: img2,
        name: "Thermal Break Profile",
        desc: " "
    },
    {
        id:3,
        image: img3,
        name: "Thermal Break Profile",
        desc: " "
    },
    {
        id:4,
        image: img4,
        name: "Thermal Break Profile",
        desc: " "
    },
    {
        id:5,
        image: img5,
        name: "Thermal Break Profile",
        desc: " "
    },
    {
        id:6,
        image: img6,
        name: "Thermal Break Profile",
        desc: " "
    },
    
] ;